import React, { Component } from 'react';

import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';

import UserSettings from './UserSettings';
import AccountsSettings from './AccountsSettings';

import CompanySettings from './Admin_panel/Companies/CompanySettings/index';
import AddPartner from './Admin_panel/Partners/AddPartner';
import Loader from './Loader/Loader';
import GuardedFeaturesRoute from './Guard/index';
// import Reporting from './Reporting/index';

// import ReviewForExport from './Reviews/ReviewForExport'

const LoadableDashboard = loadable(() => import('./Dashboards/Dashboard'), {
  fallback: <Loader />
});

const LoadableReports = loadable(() => import('./Reporting'), {
  fallback: <Loader />
});

const LoadableReviews = loadable(() => import('./Reviews'), {
  fallback: <Loader />
});

const LoadableMessages = loadable(() => import('./Messages'), {
  fallback: <Loader />
});

const LoadableMessagesPages = loadable(() => import('./Messages/Pages'), {
  fallback: <Loader />
});
const LoadableMessagesAnalytics = loadable(() => import('./Messages/Analytics'), {
  fallback: <Loader />
});

const LoadableFeedback = loadable(() => import('./Feedback'), {
  fallback: <Loader />
});
const LoadableSettings = loadable(() => import('./Settings'), {
  fallback: <Loader />
});
const LoadableAdmin_panel = loadable(() => import('./Admin_panel/Companies'), {
  fallback: <Loader />
});
const LoadableAdminUsers = loadable(() => import('./Admin_panel/AdminUsers/index'), {
  fallback: <Loader />
});
const LoadableAddUser = loadable(() => import('./Admin_panel/AdminUsers/AddAdmin'), {
  fallback: <Loader />
});
const LoadableCompanySettings = loadable(() => import('./Admin_panel/Companies/CompanySettings/index'));

const LoadablePartners = loadable(() => import('./Admin_panel/Partners/index'), {
  fallback: <Loader />
});
const LoadableAddPartner = loadable(() => import('./Admin_panel/Partners/AddPartner'), {
  fallback: <Loader />
});
const LoadableCSDashboard = loadable(() => import('./Admin_panel/CSDashboard/index'), {
  fallback: <Loader />
});
const LoadableSystemHealth = loadable(() => import('./Admin_panel/SystemHealth/index'), {
  fallback: <Loader />
});

class App extends Component {
  render() {
    const { match, currentUser } = this.props;
    return (
      <Switch>
        <Route path={`${match.url}dashboard`} exact render={props => <LoadableDashboard {...props} />} />
        <Route path={`${match.url}reviews`} render={props => <LoadableReviews {...props} />} />
        <Route path={`${match.url}messages/pages`} render={props => <LoadableMessagesPages {...props} />} />
        <Route path={`${match.url}messages/analytics`} render={props => <LoadableMessagesAnalytics {...props} />} />
        {/* <Route path={`${match.url}review/:id/:size`} component={ReviewForExport} /> */}
        <Route
          path={`${match.url}reporting`}
          render={props => <LoadableReports currentUser={currentUser} features={currentUser.features} {...props} />}
        />
        {/* <Route path={`${match.url}reporting`} render={props => <Reporting currentUser={currentUser} features={currentUser.features} {...props} />} /> */}
        <Route path={`${match.url}settings`} render={props => <LoadableSettings currentUser={currentUser} {...props} />} />

        <GuardedFeaturesRoute path={`${match.url}messages`} component={LoadableMessages} features={currentUser.features} feature={'Messaging'} />
        <GuardedFeaturesRoute path={`${match.url}feedback`} component={LoadableFeedback} features={currentUser.features} feature={'Feedback'} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/companies`} component={LoadableAdmin_panel} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/admin_users`} component={LoadableAdminUsers} role={currentUser.role} />
        <GuardedFeaturesRoute path={`${match.url}admin_panel/csdashboard`} component={LoadableCSDashboard} role={currentUser.role} />
        <GuardedFeaturesRoute path={`${match.url}admin_panel/systemhealth`} component={LoadableSystemHealth} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/admin_users/new`} component={LoadableAddUser} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/companies/new`} component={LoadableCompanySettings} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/admin_users/:id/edit`} component={LoadableAddUser} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/companies/:id/edit`} component={CompanySettings} role={currentUser.role} />
        <GuardedFeaturesRoute exact path={`${match.url}admin_panel/partners`} component={LoadablePartners} role={currentUser.role} />
        <GuardedFeaturesRoute path={`${match.url}admin_panel/partners/new`} component={LoadableAddPartner} role={currentUser.role} />
        <GuardedFeaturesRoute path={`${match.url}admin_panel/partners/:id`} component={AddPartner} role={currentUser.role} />

        <Route exact path={`${match.url}user`} component={UserSettings} />
        <Route exact path={`${match.url}accounts`} render={props => <AccountsSettings currentUser={currentUser} {...props} />} />
      </Switch>
    );
  }
}

export default App;
